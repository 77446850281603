import styled from 'styled-components';

export const SignedConsentContainer = styled.div`
  width: 100%;
  padding: 100px 20px 10px 20px;
`;

export const SignedConsentMeta = styled.div`
  font-weight: bold;
`;

export const SignedConsentButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SignedConsentDecisions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SignedConsentDecisionsTitle = styled.div`
  font-size: 2em;
  font-weight: bold;
`;

export const SignedConsentDecisionWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ececec;
`;

export const SignedConsentDecisionName = styled.span`
  font-weight: bold;
`;

export const SignedConsentDecisionDesc = styled.span`
  font-weight: normal;
`;
export const CenteredPdfContainer = styled.div`
  display: flex;
  justify-content: center;
`;
