import { gql } from '@apollo/client';

export const MARK_LEARN_ABOUT_KB360_COMPLETE = gql`
  mutation completePortalTodo {
    completePortalTodo(todoId: 212) {
      patientTodo {
        completed
      }
    }
  }
`;

export const MARK_TODO_COMPLETE = gql`
  mutation completePortalTodo($todoId: Int!) {
    completePortalTodo(todoId: $todoId) {
      patientTodo {
        completed
      }
    }
  }
`;

export interface MarkTodoCompleteVariables {
  todoId: number;
}
