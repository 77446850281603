import React, { useCallback, useState } from 'react';

import { Loader } from 'components/Loader/Loader';
import { Tabs } from 'components/v2/Tabs/Tabs';
import { Heading } from 'components/v2/Typography';
import { usePatient } from 'hooks';
import { momentInTimeZoneAndFormat } from 'kb-shared/utilities/momentHelpers';
import SignedCyclePlan from 'screens/Documents/components/SignedCyclePlan/SignedCyclePlan';
import { PatientDocument } from 'screens/Documents/Documents.types';
import { PatientConsent } from 'screens/Documents/graphql/kindbodyDocumentsQuery';
import { SignedConsent } from 'screens/SignedConsent/SignedConsent';
import { analytics } from 'utilities/analytics';
import { CONSENT_SIGNED_AT_FORMAT } from 'utilities/formatDate';

import { useDocuments } from './Documents.hook';
import { Container, Content, Description, Header } from './Documents.styled';

export const Documents = () => {
  const [tabSelected, setTabSelected] = useState(false);
  const [consentToRenderInline, setConsentToRenderInline] = useState<PatientConsent | undefined>(
    undefined
  );
  const [treatmentPlanToRender, setTreatmentPlanToRender] = useState<PatientDocument | undefined>(
    undefined
  );

  const onYourConsentsView = (consent?: PatientConsent) => {
    setConsentToRenderInline(consent);
    scrollToTop();
    analytics.track(analytics.EVENTS.SIGNED_CONSENT_VIEWED);
  };

  const onTreatmentPlansView = (treatmentPlan?: PatientDocument) => {
    setTreatmentPlanToRender(treatmentPlan);
    scrollToTop();
    analytics.track(analytics.EVENTS.SIGNED_TREATMENT_PLAN_VIEWED);
  };

  const { loading, tabs } = useDocuments(onYourConsentsView, onTreatmentPlansView);
  const { patient, loading: loadingPatient } = usePatient();

  const onChangeTab = useCallback(
    (tabId?: string) => {
      setTabSelected(!!tabId);

      if (tabId) {
        analytics.track(analytics.EVENTS.DOCUMENT_TAB_USED, { document_type: tabId });
      }
    },
    [setTabSelected]
  );

  if (loading || loadingPatient || !patient) return <Loader absoluteCentered />;

  const visibleTabs = tabs
    .filter(tab => tab.isVisible)
    .map(({ id, label, content }) => ({ id, label, content }));

  const onBackFromConsent = () => {
    setConsentToRenderInline(undefined);
    scrollToTop();
  };

  const onBackFromCyclePlanAcknowledge = () => {
    setTreatmentPlanToRender(undefined);
    scrollToTop();
  };

  if (consentToRenderInline) {
    return (
      <SignedConsent
        consentRawHtml={consentToRenderInline.patientPortalHtml}
        pdfLink={consentToRenderInline.pdfLink}
        completedAt={
          consentToRenderInline.completedAt
            ? momentInTimeZoneAndFormat(
                consentToRenderInline.completedAt,
                patient?.lab?.timeZone || 'UTC',
                CONSENT_SIGNED_AT_FORMAT
              )
            : ''
        }
        onBack={onBackFromConsent}
        consentDecisions={consentToRenderInline.decisions}
        patient={patient}
        patientNameFromConsent={consentToRenderInline.patientName}
      />
    );
  }

  if (treatmentPlanToRender && treatmentPlanToRender.jsonData?.length > 0) {
    return (
      <SignedCyclePlan
        cyclePlans={treatmentPlanToRender.jsonData}
        patient={patient}
        onBack={onBackFromCyclePlanAcknowledge}
      />
    );
  }

  return (
    <Container>
      <Header $tabSelected={tabSelected}>
        <Heading noMargin>Documents</Heading>
        <Description tag="p" size="md">
          Upload recent medical reports to your Kindbody patient record for your care team to
          review. Common examples include pap smears, mammograms, prior fertility treatments, STD
          screening, recent bloodwork, etc.
        </Description>
      </Header>
      <Content>
        <Tabs tabs={visibleTabs} tabUrlKey="tab" onChangeTab={onChangeTab} noMargin />
      </Content>
    </Container>
  );
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
