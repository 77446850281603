import React, { useState } from 'react';

import { SharedUploadModal, UPLOAD_CATEGORY } from 'components/SharedUploadModal/SharedUploadModal';
import { Button } from 'components/v2/Buttons/Button';
import { ClampedTextContainer } from 'components/v2/ClampedTextContainer/ClampedTextContainer';
import { Tooltip } from 'components/v2/Tooltip/Tooltip';
import { Heading, Text } from 'components/v2/Typography';
import { PatientToDo, graphql, isPatientToDo } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { useBreakpoints } from 'utilities/useBreakpoints';

import {
  MARK_LEARN_ABOUT_KB360_COMPLETE,
  MARK_TODO_COMPLETE,
  MarkTodoCompleteVariables
} from '../ToDoItems/ToDoItems.graphql';
import { isToDoForCompletingOnView, isToDoForHiding } from '../ToDoItems/ToDoItems.utils';
import { INTAKE_AND_TODO } from '../ToDoWidget.graphql';
import {
  GoForwardArrow,
  GoUpArrow,
  ToDoItemActionsArea,
  ToDoItemActionsButtons,
  ToDoItemConsentWarning,
  ToDoItemContainer,
  ToDoItemContentArea,
  ToDoItemTextArea,
  ToDoItemTitle,
  ToDoItemTitleArea,
  ToDoItemTitleContainer,
  ToDoItemTitleNumberContainer
} from './ToDoItem.styled';
import { ToDoItemDescription } from './ToDoItemDescription/ToDoItemDescription';

export const ToDoItem = ({
  todo,
  onToDoCompleted,
  medicationsInstructionsFlagIsOn,
  orderNumber
}: {
  todo: PatientToDo;
  onToDoCompleted: Function;
  medicationsInstructionsFlagIsOn: boolean;
  orderNumber?: number;
}) => {
  const { isDesktop } = useBreakpoints();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [contentCollapsed, setContentCollapsed] = useState(isDesktop ? false : true);
  const [hasTitleClamped, setHasTitleClamped] = useState(false);
  if (!isPatientToDo(todo)) return null;

  const { title, description, linkUrl, buttonText } = todo;
  const headingText = todo.headingText ?? title;
  const category = todo.category;

  const onButtonClick = async () => {
    if (isToDoForHiding(todo)) {
      try {
        await graphql.client.mutate({ mutation: MARK_LEARN_ABOUT_KB360_COMPLETE });
      } catch (error) {
        BugTracker.notify(error, 'MarkKB360ToDoComplete');
      }
    } else if (isToDoForCompletingOnView(todo)) {
      try {
        await graphql.client.mutate<unknown, MarkTodoCompleteVariables>({
          mutation: MARK_TODO_COMPLETE,
          variables: {
            todoId: todo.todoId
          }
        });
        // If TODO marked as completed doesn't result with redirected to a new page in a current tab,
        // patient will keep seeing TODO marked as complete as long as screen isn't rerendered (e.g. because of reload).
        // This for example happens when TODO marked as completed opens PDF file. Browser may decide to open PDF in a new tab.

        graphql.client.refetchQueries({ include: [INTAKE_AND_TODO] });
      } catch (error) {
        // in case of error, we will just log the error and let patient be redirected
        BugTracker.notify(error, 'MarkToDoComplete');
      }
    }

    if (linkUrl) {
      window.location.href = linkUrl;
    }

    setUploadModalVisible(true);
  };

  const onToDoTitleClick = () => {
    setContentCollapsed(!contentCollapsed);
  };

  const showConsentWarning = category === 'consent' && medicationsInstructionsFlagIsOn;
  const ctaText = linkUrl ? buttonText || 'Learn more' : 'Upload';
  const ctaButtonVisible = linkUrl || isUploadCategory(category);

  return (
    <>
      <ToDoItemContainer $active={!contentCollapsed}>
        <ToDoItemTitleArea onClick={onToDoTitleClick}>
          <ToDoItemTitleContainer>
            <ToDoItemTitle>
              <ToDoItemTitleNumberContainer $active={!contentCollapsed}>
                <Text fontStyle={contentCollapsed ? 'regular' : 'bold'} tag="span">
                  {orderNumber}
                </Text>
              </ToDoItemTitleNumberContainer>
              <Text fontStyle={contentCollapsed ? 'regular' : 'bold'} tag="span">
                {todo.title}
              </Text>
            </ToDoItemTitle>
            {contentCollapsed ? <GoForwardArrow /> : <GoUpArrow />}
          </ToDoItemTitleContainer>
        </ToDoItemTitleArea>
        <ToDoItemContentArea pose={contentCollapsed ? 'hidden' : 'visible'}>
          <ToDoItemTextArea>
            <Tooltip
              key={`title-${todo.id}`}
              tooltip={headingText}
              displayTooltip={isDesktop && hasTitleClamped}
              minWidth="220px"
            >
              <Heading tag="h1">
                {isDesktop ? (
                  <ClampedTextContainer
                    lineClamp={2}
                    onClampVisibilityChange={clamped => setHasTitleClamped(clamped)}
                  >
                    {headingText}
                  </ClampedTextContainer>
                ) : (
                  <>{headingText}</>
                )}
              </Heading>
            </Tooltip>
            <ToDoItemDescription description={description} />
            {showConsentWarning && (
              <ToDoItemConsentWarning
                title="WARNING:"
                message="Consents must be signed prior to the start of your cycle. If not signed, this will prevent you from seeing your medication dosages for your treatment cycle on the portal."
              />
            )}
          </ToDoItemTextArea>
          {ctaButtonVisible && (
            <ToDoItemActionsArea>
              <ToDoItemActionsButtons>
                <Button category="primary-dark" label={ctaText} onClick={onButtonClick} />
              </ToDoItemActionsButtons>
            </ToDoItemActionsArea>
          )}
        </ToDoItemContentArea>
      </ToDoItemContainer>

      {uploadModalVisible && isUploadCategory(category) && (
        <SharedUploadModal
          onClose={() => setUploadModalVisible(false)}
          onUploadCompleted={onToDoCompleted}
          category={category}
          visible={uploadModalVisible}
        />
      )}
    </>
  );
};

const isUploadCategory = (category: string | undefined): category is UPLOAD_CATEGORY =>
  category === 'upload insurance card' || category === 'upload id' || category === 'profile photo';
