import { gql } from '@apollo/client';

import { NullableString } from 'kb-shared';

import { PatientDocument } from '../Documents.types';

export interface KindbodyDocument {
  id: string;
  patientId: string;
  pdfResults: string;
  updatedAt: string;
  htmlResults: string;
  createdAt: string;
  chReportId: string;
}

export interface PatientConsent {
  id: string;
  completedAt?: string;
  patientName: NullableString;
  patientPortalHtml: string;
  pdfLink: string;
  consent: {
    name: string;
    id: string;
  };
  decisions: Array<{
    consentDecision: {
      id: number;
      name: string;
      description: string;
    };
    consentOption: {
      id: number;
      name: string;
      description: string;
    };
  }>;
}

export interface ConsentDocument {
  name: string;
  date: string;
  link: string;
  id: string;
}

export interface KindbodyDocumentsResponse {
  kindbodyDocs: KindbodyDocument[];
  patientConsents: PatientConsent[];
  documents: PatientDocument[];
}

export const kindbodyDocumentsQuery = gql`
  query kindbodyDocuments {
    labOrderReports {
      id
      patientId
      chReportId
      createdAt
      updatedAt
      pdfResults
      htmlResults
    }
    patientConsents {
      id
      completedAt
      patientName
      patientPortalHtml
      pdfLink
      consent {
        name
        id
      }
      decisions {
        consentOption {
          id
          name
          description
        }
        consentDecision {
          id
          name
          description
        }
      }
    }
    documents {
      category
      date
      jsonData
      name
      documentLink
      databaseId
    }
  }
`;
